import type { Module } from 'vuex';
import type { TPlatformWaste } from '@contimo/api/src/api/platformWastes';
import type { TApiPaginationQuery, TPaginationMeta } from '@contimo/types/src/Api';
import { platformWastes, merchantWastes } from '@contimo/api/src/api';
import {
  TMerchantWaste,
  TMerchantWasteCreateBody,
  TMerchantWasteUpdateBody,
} from '@contimo/api/src/api/merchantWastes';
import { removeFromArrayByKey, updateOrPushInArray } from '@/utils/store';
import type { TRootStore } from '@/store';
import {
  CREATE_MERCHANT_WASTE,
  DELETE_MERCHANT_WASTE,
  GET_MERCHANT_WASTES,
  GET_PLATFORM_WASTES,
  INIT_MERCHANT_WASTES,
  UPDATE_MERCHANT_WASTE,
  UPLOAD_MERCHANT_WASTE_PLACEHOLDER,
} from '../actionTypes';
import {
  REMOVE_MERCHANT_WASTE,
  SET_MERCHANT_WASTE,
  SET_MERCHANT_WASTES,
  SET_PLATFORM_WASTE,
  SET_PLATFORM_WASTES,
} from '../mutationsTypes';
import { ALL_MERCHANT_WASTES, ALL_PLATFORM_WASTES } from '../gettersTypes';
import { decimalPriceToInteger } from '@/utils/decimalToInteger';

export interface IMerchantWastesStoreState {
  loading: boolean;
  createLoading: boolean;
  updateLoading: boolean;
  platformWastes: TPlatformWaste[];
  merchantWastes: any[];
  pagination: TPaginationMeta | null;
}

function parseMerchantWastePrices(merchantWaste: TMerchantWaste) {
  merchantWaste.prices.forEach((price) => {
    price.pricePerCubicMeter /= 100;
  });
  merchantWaste.basePrice /= 100;
}

function convertMerchantWastePrices(
  merchantWaste: TMerchantWasteUpdateBody | TMerchantWasteCreateBody,
) {
  if (merchantWaste.prices) {
    merchantWaste.prices.forEach((price) => {
      price.pricePerCubicMeter = decimalPriceToInteger(price.pricePerCubicMeter);
    });
  }
  merchantWaste.basePrice = decimalPriceToInteger(merchantWaste.basePrice);
}

type TMerchantWastesStore = Module<IMerchantWastesStoreState, TRootStore>;

const merchantWastesStore: TMerchantWastesStore = {
  state: () => ({
    loading: false,
    createLoading: false,
    updateLoading: false,
    platformWastes: [],
    merchantWastes: [],
    pagination: null,
  }),

  getters: {
    [ALL_MERCHANT_WASTES]: (state) => {
      return state.merchantWastes.sort((a, b) => a.platformWasteId - b.platformWasteId);
    },
    [ALL_PLATFORM_WASTES]: (state) => state.platformWastes,
  },

  mutations: {
    [SET_PLATFORM_WASTE](state, data: TPlatformWaste) {
      updateOrPushInArray(state.platformWastes, data);
    },
    [SET_PLATFORM_WASTES](state, data: TPlatformWaste[]) {
      data.forEach((value) => {
        updateOrPushInArray(state.platformWastes, value);
      });
    },
    [SET_MERCHANT_WASTE](state, data: TMerchantWaste) {
      updateOrPushInArray(state.merchantWastes, data);
    },
    [SET_MERCHANT_WASTES](state, data: TMerchantWaste[]) {
      data.forEach((value) => {
        updateOrPushInArray(state.merchantWastes, value);
      });
    },
    [REMOVE_MERCHANT_WASTE](state, id: number) {
      removeFromArrayByKey(state.merchantWastes, id);
    },
    setMerchantWastesLoading(state, loading: boolean) {
      state.loading = loading;
    },
    setCreateLoading(state, loading: boolean) {
      state.createLoading = loading;
    },
    setUpdateLoading(state, loading: boolean) {
      state.updateLoading = loading;
    },
    setMerchantWastesPagination(state, pagination: TPaginationMeta | null) {
      state.pagination = pagination;
    },
  },

  actions: {
    async [GET_PLATFORM_WASTES]({ commit }) {
      const { data } = await platformWastes.index();
      commit(SET_PLATFORM_WASTES, data);
    },
    async [GET_MERCHANT_WASTES]({ commit }, pagination: TApiPaginationQuery = {}) {
      const { data } = await merchantWastes.index(pagination);
      data.data.forEach((merchantWaste: TMerchantWaste) => parseMerchantWastePrices(merchantWaste));
      commit(SET_MERCHANT_WASTES, data.data);
      commit('setMerchantWastesPagination', data.meta, {
        root: false,
      });
    },
    async [INIT_MERCHANT_WASTES]({ dispatch, commit }) {
      commit('setMerchantWastesLoading', true);
      await dispatch(GET_PLATFORM_WASTES);
      await dispatch(GET_MERCHANT_WASTES, {
        limit: 50,
      });
      commit('setMerchantWastesLoading', false);
    },
    async [CREATE_MERCHANT_WASTE]({ commit }, body: TMerchantWasteCreateBody) {
      commit('setCreateLoading', true);
      try {
        const { placeholder, ...createBody } = body;
        convertMerchantWastePrices(createBody);
        let { data } = await merchantWastes.store(createBody);
        if (placeholder) {
          const uploadReturn = await merchantWastes.uploadPlaceholder(data.id, placeholder);
          data = uploadReturn.data;
        }
        parseMerchantWastePrices(data);
        commit(SET_MERCHANT_WASTE, data);
        return body;
      } finally {
        commit('setCreateLoading', false);
      }
    },
    async [UPDATE_MERCHANT_WASTE](
      { commit },
      { id, body }: { id: number; body: TMerchantWasteUpdateBody },
    ) {
      commit('setUpdateLoading', true);
      try {
        const { placeholder, ...updateBody } = body;
        convertMerchantWastePrices(updateBody);
        let { data } = await merchantWastes.update(id, updateBody);
        if (placeholder) {
          const uploadReturn = await merchantWastes.uploadPlaceholder(data.id, placeholder);
          data = uploadReturn.data;
        }
        parseMerchantWastePrices(data);
        commit(SET_MERCHANT_WASTE, data);
        return data;
      } finally {
        commit('setUpdateLoading', false);
      }
    },
    async [UPLOAD_MERCHANT_WASTE_PLACEHOLDER](
      { commit },
      { id, placeholder }: { id: number; placeholder: File },
    ) {
      commit('setUpdateLoading', true);
      try {
        const { data } = await merchantWastes.uploadPlaceholder(id, placeholder);
        commit(SET_MERCHANT_WASTE, data);
        return data;
      } finally {
        commit('setUpdateLoading', false);
      }
    },
    async [DELETE_MERCHANT_WASTE]({ commit }, id: number) {
      commit('setUpdateLoading', true);
      try {
        const { data } = await merchantWastes.destroy(id);
        commit(REMOVE_MERCHANT_WASTE, data.id);
        return data;
      } finally {
        commit('setUpdateLoading', false);
      }
    },
  },
};

export default merchantWastesStore;
