import type { Module } from 'vuex';
import { TShop, TShopUpdateBody } from '@contimo/api/src/api/shops';
import { shops } from '@contimo/api/src/api';
import { customAlphabet } from 'nanoid';
import axios from 'axios';
import type { TRootStore } from '@/store';
import slugify from '@/utils/slugify';
import { CREATE_SHOP, GET_SHOP, UPDATE_SHOP } from '../actionTypes';
import {
  SET_SHOP,
  SET_SHOP_ACTIVATION_LOADING,
  SET_SHOP_LOADING,
  SET_SHOP_SUBMIT_LOADING,
} from '../mutationsTypes';
import { WAIT_FOR_SHOP_ACTIVATION, THIS_SHOP, SHOP_URL } from '../gettersTypes';

const nanoid = customAlphabet(
  '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-',
  10,
);

export interface IShopStoreState {
  loading: boolean;
  activationLoading: boolean;
  submitLoading: boolean;
  shop: TShop | null;
}

type TShopStore = Module<IShopStoreState, TRootStore>;

const shopStore: TShopStore = {
  state: () => ({
    loading: false,
    activationLoading: false,
    submitLoading: false,
    shop: null,
  }),

  getters: {
    [WAIT_FOR_SHOP_ACTIVATION]: (state) => {
      return state.activationLoading || state.loading || state.submitLoading;
    },
    [THIS_SHOP]: (state) => {
      return state.shop;
    },
    [SHOP_URL]: (state) => {
      if (state.shop && state.shop.slug) {
        return process.env.VUE_APP_STOREFRONT_BASE?.replace('[*]', state.shop.slug);
      }
      return null;
    },
  },

  mutations: {
    [SET_SHOP](state, shop: TShop | null) {
      state.shop = shop;
    },
    [SET_SHOP_ACTIVATION_LOADING](state, loading: boolean) {
      state.activationLoading = loading;
    },
    [SET_SHOP_LOADING](state, loading: boolean) {
      state.loading = loading;
    },
    [SET_SHOP_SUBMIT_LOADING](state, loading: boolean) {
      state.submitLoading = loading;
    },
  },

  actions: {
    async [GET_SHOP]({ commit, dispatch }) {
      commit(SET_SHOP_LOADING, true);
      try {
        const { data } = await shops.index();
        commit(SET_SHOP, data);
        commit(SET_SHOP_LOADING, false);
      } catch (_) {
        dispatch(CREATE_SHOP);
      } finally {
        commit(SET_SHOP_LOADING, false);
      }
    },
    async [CREATE_SHOP]({ commit, rootState, dispatch }) {
      commit(SET_SHOP_LOADING, true);
      try {
        const body = {
          slug: slugify(rootState.merchant.merchant.name, '-'),
          domain: null,
          color: '#0c55a4',
          active: false,
          cardTitle: 'Container mieten und stressfrei entsorgen',
          cardLine1: 'Anlieferung innerhalb von 72h',
          cardLine2: 'Bis zu 14 Tage mietfrei stellen',
          cardLine3: 'Sichere Bezahlung',
        };

        try {
          await shops.store(body);
        } catch (e) {
          if (axios.isAxiosError(e) && e.response?.data.code === 'E_SHOP_SLUG_ALREADY_USED') {
            body.slug = slugify(`${rootState.merchant.merchant.name}-${nanoid()}`, '-');
            await shops.store(body);
          }
          throw e;
        }
        await dispatch(GET_SHOP);
      } finally {
        commit(SET_SHOP_LOADING, false);
      }
    },
    async [UPDATE_SHOP](
      { commit, state },
      payload: TShopUpdateBody & { logo: File; coverPicture: File },
    ) {
      if (state.shop && !payload.logo && !payload.coverPicture) {
        commit(SET_SHOP_SUBMIT_LOADING, true);
        try {
          const { data } = await shops.update(state.shop.id, payload);
          commit(SET_SHOP, data);
        } finally {
          commit(SET_SHOP_SUBMIT_LOADING, false);
        }
      } else if (state.shop && payload.logo) {
        commit(SET_SHOP_SUBMIT_LOADING, true);
        try {
          const { data } = await shops.uploadLogo(state.shop.id, payload.logo);
          commit(SET_SHOP, data);
        } finally {
          commit(SET_SHOP_SUBMIT_LOADING, false);
        }
      } else if (state.shop && payload.coverPicture) {
        try {
          commit(SET_SHOP_SUBMIT_LOADING, true);
          const { data } = await shops.uploadCoverPicture(state.shop.id, payload.coverPicture);
          commit(SET_SHOP, data);
        } finally {
          commit(SET_SHOP_SUBMIT_LOADING, false);
        }
      }
    },
  },
};

export default shopStore;
