import type { Module } from 'vuex';
import type Stripe from 'stripe';
import { merchants } from '@contimo/api/src/api';
import address, { IUpdateAddressBody } from '@contimo/api/src/api/address';
import type { TRootStore } from '@/store';
import {
  CREATE_PAYOUT_ACCOUNT,
  CREATE_STRIPE_ACCOUNT,
  GET_MERCHANT,
  GET_STRIPE_ACCOUNT,
  GET_STRIPE_ONBOARDING_LINK,
  INIT_MERCHANT,
  PLATFORM_ONBOARD_MERCHANT,
  UPDATE_MERCHANT_ADDRESS,
  UPDATE_MERCHANT_EMAILS,
  UPDATE_MERCHANT_PHONE,
} from '../actionTypes';
import { SET_MERCHANT, SET_STRIPE_ACCOUNT, SET_STRIPE_ONBOARDING_LINK } from '../mutationsTypes';
import {
  NEEDS_ONBOARDING,
  STRIPE_ACCOUNT_REQUIREMENTS,
  STRIPE_CHARGES_ENABLED,
  STRIPE_PAYOUTS_ENABLED,
  THIS_MERCHANT,
} from '../gettersTypes';

export type TAccountDisabledReason =
  | 'requirements.past_due'
  | 'requirements.pending_verification'
  | 'listed'
  | 'platform_paused'
  | 'rejected.fraud'
  | 'rejected.listed'
  | 'rejected.terms_of_service'
  | 'rejected.other'
  | 'under_review'
  | 'other';

export interface IMerchantStoreState {
  loading: boolean;
  updateLoading: boolean;
  merchant: {
    stripeAccount?: Stripe.Account | null;
    [key: string]: any;
  } | null;
  stripeOnboardingLink: Stripe.AccountLink | null;
  payoutAccounts: Stripe.BankAccount[];
}

type TMerchantStore = Module<IMerchantStoreState, TRootStore>;

const merchantStore: TMerchantStore = {
  state: () => ({
    loading: false,
    updateLoading: false,
    merchant: null,
    stripeOnboardingLink: null,
    payoutAccounts: [],
  }),

  getters: {
    [THIS_MERCHANT]: (state) => {
      if (state.merchant) {
        return {
          ...state.merchant,
          onboardingLink: state.stripeOnboardingLink,
          countryShort: state.merchant?.address.location.countryShort || null,
        };
      }
      return null;
    },
    [STRIPE_CHARGES_ENABLED]: (state) => state.merchant?.stripeAccount?.charges_enabled || false,
    [STRIPE_PAYOUTS_ENABLED]: (state) => state.merchant?.stripeAccount?.payouts_enabled || false,
    [STRIPE_ACCOUNT_REQUIREMENTS]: (state): boolean | string => {
      const requirements = state.merchant?.stripeAccount?.requirements;
      const disabledReason = requirements?.disabled_reason;
      switch (disabledReason) {
        case 'requirements.past_due':
          if (requirements?.past_due) {
            if (requirements.past_due.length > 1) return 'onboarding';
          }
          return requirements?.past_due?.[0] || true;
        case 'requirements.pending_verification':
        case 'under_review':
          return 'pending';
        default:
          return disabledReason || false;
      }
    },
    [NEEDS_ONBOARDING]: (state, getters) => {
      const {
        stripeChargesEnabled,
        stripePayoutsEnabled,
        stripeAccountRequirements,
        thisMerchant,
      } = getters;
      return (
        !stripeChargesEnabled ||
        !stripePayoutsEnabled ||
        stripeAccountRequirements ||
        !thisMerchant.paymentOnboarded ||
        !thisMerchant.platformOnboarded
      );
    },
  },

  mutations: {
    [SET_MERCHANT](state, merchant: Record<string, any> | null) {
      state.merchant = merchant;
    },
    [SET_STRIPE_ACCOUNT](state, stripeAccount: Stripe.Account | null) {
      if (state.merchant) {
        state.merchant.stripeAccount = stripeAccount;
      }
    },
    [SET_STRIPE_ONBOARDING_LINK](state, onboardingLink: Stripe.AccountLink | null) {
      state.stripeOnboardingLink = onboardingLink;
    },
  },

  actions: {
    async [GET_MERCHANT]({ state, commit }, id) {
      state.loading = true;
      try {
        const { data } = await merchants.show(id, true);
        commit(SET_MERCHANT, data);
      } finally {
        state.loading = false;
      }
    },
    async [INIT_MERCHANT]({ dispatch }, merchantId) {
      await dispatch(GET_MERCHANT, merchantId);
    },
    async [CREATE_STRIPE_ACCOUNT](
      { commit },
      { merchantId, businessType }: { merchantId: number; businessType: 'company' | 'individual' },
    ) {
      const { data } = await merchants.payments.createStripeAccount(merchantId, businessType);
      commit(SET_MERCHANT, data);
      return data;
    },
    async [GET_STRIPE_ACCOUNT]({ commit }, merchantId: number) {
      const { data } = await merchants.payments.getStripeAccount(merchantId);
      commit(SET_STRIPE_ACCOUNT, data);
      return data;
    },
    async [GET_STRIPE_ONBOARDING_LINK](
      { commit },
      {
        merchantId,
        type,
        refreshUrl,
        returnUrl,
      }: {
        merchantId: number;
        type: 'account_onboarding' | 'account_update';
        refreshUrl?: string;
        returnUrl?: string;
      },
    ) {
      const { data } = await merchants.payments.getOnboardingLink(
        merchantId,
        type,
        refreshUrl,
        returnUrl,
      );
      commit(SET_STRIPE_ONBOARDING_LINK, data);
      return data;
    },
    async [CREATE_PAYOUT_ACCOUNT](
      { state, dispatch },
      { merchantId, body }: { merchantId: number; body: any },
    ) {
      const { data } = await merchants.payments.createPayoutAccount(merchantId, body);
      state.payoutAccounts.push(data);
      await dispatch(GET_MERCHANT, merchantId);
      return data;
    },
    async [UPDATE_MERCHANT_ADDRESS]({ state, dispatch }, payload: IUpdateAddressBody) {
      if (state.merchant?.id) {
        state.updateLoading = true;
        try {
          const { data } = await address.update(state.merchant.addressId, payload);
          await dispatch(GET_MERCHANT, state.merchant.id);
          return data;
        } finally {
          state.updateLoading = false;
        }
      }
      return null;
    },
    async [UPDATE_MERCHANT_EMAILS](
      { state, dispatch },
      { invoiceEmail, notificationEmail }: { invoiceEmail: string; notificationEmail: string },
    ) {
      if (state.merchant?.id) {
        state.updateLoading = true;
        try {
          const { data } = await merchants.update(state.merchant.id, {
            invoiceEmail,
            notificationEmail,
          });
          await dispatch(GET_MERCHANT, state.merchant.id);
          return data;
        } finally {
          state.updateLoading = false;
        }
      }
      return null;
    },
    async [UPDATE_MERCHANT_PHONE]({ state, dispatch }, { phone }: { phone: string }) {
      if (state.merchant?.id) {
        state.updateLoading = true;
        try {
          const { data } = await merchants.update(state.merchant.id, {
            phone,
          });
          await dispatch(GET_MERCHANT, state.merchant.id);
          return data;
        } finally {
          state.updateLoading = false;
        }
      }
      return null;
    },
    async [PLATFORM_ONBOARD_MERCHANT]({ state, commit }) {
      if (state.merchant?.id) {
        state.loading = true;
        try {
          const { data } = await merchants.platformOnboard(state.merchant?.id);
          commit(SET_MERCHANT, data);
          return data;
        } finally {
          state.loading = false;
        }
      }
      return null;
    },
  },
};

export default merchantStore;
