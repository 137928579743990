import type { Module } from 'vuex';
import type { TSignupData, TSignupType } from '@contimo/api/src/api/authentication';
import { authentication } from '@contimo/api/src/api';
import { TPostalCode } from '@contimo/api/src/api/postalCodes';
import { AxiosResponse } from 'axios';
import type { TRootStore } from '@/store';
import {
  RESEND_VERIFICATION,
  SIGNUP_INVITED_MANAGER,
  SIGNUP_MERCHANT,
  VERIFY_EMAIL,
} from '../actionTypes';
import { SET_SIGNUP_STEP } from '../mutationsTypes';
import plausible from '@/utils/plausible';

export type TSignupStep = 'data' | 'password' | 'verify';

export interface ISignupStoreState {
  signupStep: TSignupStep;
  signupData: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    type: TSignupType;
    termsVersion: string;
    phone: string;
  };
  managerSignupData: {
    inviteToken: string;
  };
  merchantSignupData: {
    merchantName: string;
    address: {
      street: string;
      number: string;
      postalCodeId: string;
      city?: string | null;
      countryShort: string;
    };
  };
  merchantHelper: {
    selectedPostalCode: TPostalCode | null;
    customCity: boolean;
  };
}

type TSignupStore = Module<ISignupStoreState, TRootStore>;

const signupStore: TSignupStore = {
  state: () => ({
    signupStep: 'data',
    signupData: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      type: 'merchant',
      termsVersion: '20210524',
      phone: '',
      terms: 1,
      privacy: 1,
    },
    managerSignupData: {
      inviteToken: '',
    },
    merchantSignupData: {
      merchantName: '',
      address: {
        street: '',
        number: '',
        postalCodeId: '',
        city: null,
        countryShort: 'DE',
      },
    },
    merchantHelper: {
      selectedPostalCode: null,
      customCity: false,
    },
  }),

  getters: {},

  mutations: {
    [SET_SIGNUP_STEP](state, step: TSignupStep) {
      state.signupStep = step;
    },
  },

  actions: {
    async [SIGNUP_MERCHANT]({ state }): Promise<AxiosResponse<any>> {
      return new Promise((res, rej) => {
        state.signupData.type = 'merchant';
        authentication
          .signup({
            ...state.merchantSignupData,
            ...state.signupData,
          })
          .then((resp) => {
            plausible('Registrierung');
            res(resp);
          })
          .catch((e) => {
            rej(e);
          });
      });
    },
    async [SIGNUP_INVITED_MANAGER](
      _,
      {
        signupData,
        email,
        signature,
      }: { signupData: TSignupData; email: string; signature: string },
    ) {
      signupData.type = 'manager';
      return authentication.acceptInvite(signupData, email, signature);
    },
    async [RESEND_VERIFICATION]({ state }, email: string | null) {
      return authentication.resendVerificationMail(email || state.signupData.email);
    },
    async [VERIFY_EMAIL](_, { email, signature }: { email: string; signature: string }) {
      plausible('Email Verifizieren');
      return authentication.verifyEmail(email, signature);
    },
  },
};

export default signupStore;
