import Vue from 'vue';
import VueRouter, { Route, RouteConfig } from 'vue-router';
import store from '../store';
import merchantRoutes from './merchant';
import customerRoutes from './customer';
import { SET_NEXT_ROUTE } from '../store/mutationsTypes';
import { INIT_AUTH } from '../store/actionTypes';

Vue.use(VueRouter);

const canUserAccess = async (to: Route) => {
  const setNextRoute = (nextRoute: string) => store.commit(SET_NEXT_ROUTE, nextRoute);
  const initAuth = async () => store.dispatch(INIT_AUTH);
  const { authIsReady } = store.getters;

  const run = () => {
    const { accessToken, isManager, isAdmin } = store.getters;
    let defaultRoute = '/c/home';
    if (isManager) defaultRoute = '/m/home';
    if (isAdmin) defaultRoute = '/a/home';
    const routesArea = to.path.substr(0, 3);
    const isMerchantArea = routesArea === '/m/';
    const isCustomerArea = routesArea === '/c/';
    const isAdminArea = routesArea === '/a/';

    if (!to.meta?.public && !accessToken) {
      setNextRoute(to.fullPath);
      return {
        canAccess: false,
        defaultRoute,
      };
    }
    if (
      (to.meta?.public && accessToken) ||
      (accessToken &&
        ((isMerchantArea && !isManager) ||
          (isAdminArea && !isAdmin) ||
          (isCustomerArea && (isManager || isAdmin))))
    ) {
      return {
        canAccess: false,
        defaultRoute,
      };
    }
    return {
      canAccess: true,
      defaultRoute,
    };
  };

  if (!authIsReady) {
    await initAuth();
    return run();
  }
  return run();
};

const routes: RouteConfig[] = [
  {
    path: '/login',
    component: () => import(/* webpackChunkName: "login" */ '../ui/views/auth/Login.vue'),
    meta: {
      public: true,
      title: 'login',
    },
  },
  {
    path: '/accept-invite/:email',
    component: () => import(/* webpackChunkName: "login" */ '../ui/views/auth/AcceptInvite.vue'),
    meta: {
      public: true,
      title: 'acceptInvite',
    },
  },
  {
    path: '/request-password-reset',
    component: () =>
      import(/* webpackChunkName: "login" */ '../ui/views/auth/RequestPasswordReset.vue'),
    meta: {
      public: true,
      title: 'resetPassword',
    },
  },
  {
    path: '/reset-password/:email',
    component: () => import(/* webpackChunkName: "login" */ '../ui/views/auth/ResetPassword.vue'),
    meta: {
      public: true,
      title: 'resetPassword',
    },
  },
  {
    path: '/signup/:step/:param?',
    component: () => import(/* webpackChunkName: "signup" */ '../ui/views/auth/SignupMerchant.vue'),
    meta: {
      public: true,
      title: 'signup',
    },
  },
  {
    path: '/redirect',
    redirect: 'login',
  },
  {
    path: '/m',
    component: () =>
      import(/* webpackChunkName: "merchantview" */ '../ui/views/merchant/index.vue'),
    children: merchantRoutes,
  },
  {
    path: '/c',
    component: () =>
      import(/* webpackChunkName: "customerview" */ '../ui/views/customer/index.vue'),
    children: customerRoutes,
  },
  {
    path: '*',
    redirect: '/redirect',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.NODE_ENV === 'production' ? '/app' : '',
  routes,
});

// const defaultHomeRoute = '/home';

router.beforeEach(async (to, _, next) => {
  // canUserAccess() returns `true` or `false`
  const { canAccess, defaultRoute } = await canUserAccess(to);
  if (!canAccess) {
    next(to.meta?.public ? defaultRoute : '/login');
  } else {
    next();
  }
});

export default router;
