export const INIT_AUTH = 'initAuth';
export const LOGIN = 'login';
export const LOGOUT = 'logout';

export const INIT_USER = 'initUser';
export const GET_USER = 'getUser';

export const SIGNUP_MERCHANT = 'signupMerchant';
export const SIGNUP_INVITED_MANAGER = 'signupInvitedManager';
export const RESEND_VERIFICATION = 'resendVerification';
export const VERIFY_EMAIL = 'verifyEmail';

export const GET_COUNTRIES = 'getCountries';
export const INIT_USER_INTERFACE = 'initUserInterface';
export const INIT_EVENT_LISTENERS = 'initEventListeners';

export const GET_MERCHANT = 'getMerchant';
export const UPDATE_MERCHANT_ADDRESS = 'updateMerchantAddress';
export const UPDATE_MERCHANT_EMAILS = 'updateMerchantEmails';
export const UPDATE_MERCHANT_PHONE = 'updateMerchantPhone';
export const INIT_MERCHANT = 'initMerchant';
export const CREATE_STRIPE_ACCOUNT = 'createStripeAccount';
export const GET_STRIPE_ACCOUNT = 'getStripeAccount';
export const GET_STRIPE_ONBOARDING_LINK = 'getStripeOnboardingLink';
export const CREATE_PAYOUT_ACCOUNT = 'createPayoutAccount';
export const PLATFORM_ONBOARD_MERCHANT = 'platformOnboardMerchant';

export const SEARCH_POSTAL_CODES = 'searchPostalCodes';

export const GET_DELIVERY_AREAS = 'getDeliveryAreas';
export const GET_DELIVERY_AREA = 'getDeliveryArea';
export const CREATE_DELIVERY_AREA = 'createDeliveryArea';
export const UPDATE_DELIVERY_AREA = 'updateDeliveryArea';
export const DELETE_DELIVERY_AREA = 'deleteDeliveryArea';

export const INIT_MERCHANT_WASTES = 'initMerchantWastes';
export const GET_PLATFORM_WASTES = 'getPlatformWastes';
export const GET_PLATFORM_WASTE = 'getPlatformWaste';
export const GET_MERCHANT_WASTES = 'getMerchantWastes';
export const CREATE_MERCHANT_WASTE = 'createMerchantWaste';
export const UPDATE_MERCHANT_WASTE = 'updateMerchantWaste';
export const DELETE_MERCHANT_WASTE = 'deleteMerchantWaste';
export const UPLOAD_MERCHANT_WASTE_PLACEHOLDER = 'uploadMerchantWastePlaceholder';

export const GET_PRODUCTS = 'getProducts';
export const GET_PRODUCT = 'getProduct';
export const CREATE_PRODUCT_CONTAINER = 'createProductContainer';
export const UPDATE_PRODUCT_CONTAINER = 'updateProductContainer';
export const DELETE_PRODUCT = 'deleteProduct';

export const GET_SELLING_PRICES = 'getSellingPrices';
export const GET_SELLING_PRICE = 'getSellingPrice';
export const CREATE_SELLING_PRICE = 'createSellingPrice';
export const UPDATE_SELLING_PRICE = 'updateSellingPrice';

export const GET_SHOP = 'getShop';
export const CREATE_SHOP = 'createShop';
export const UPDATE_SHOP = 'updateShop';

export const GET_PAGES = 'getPages';
export const GET_PAGE = 'getPage';
export const CREATE_PAGE = 'createPage';
export const UPDATE_PAGE = 'updatePage';

export const GET_MANAGER_INVITE = 'getManagerInvite';
export const GET_MANAGER_INVITES = 'getManagerInvites';
export const CREATE_MANAGER_INVITE = 'createManagerInvites';
export const RESEND_MANAGER_INVITE = 'resendManagerInvite';
export const DELETE_MANAGER_INVITE = 'deleteManagerInvite';

export const GET_SINGLE_USER = 'getUser';
export const GET_USERS = 'getUsers';

export const GET_SINGLE_MANAGER = 'getManager';
export const GET_MANAGERS = 'getManagers';
export const UPDATE_MANAGER = 'updateManager';

export const REQUEST_PASSWORD_RESET = 'requestPasswordReset';
export const RESET_PASSWORD = 'resetPassword';

export const GET_ORDERS = 'getOrders';
export const GET_ORDER = 'getOrder';

export const CREATE_PAYOUT = 'createPayout';
export const GET_PENDING_PAYOUTS = 'getPendingPayouts';
export const GET_BALANCE = 'getBalance';

export const GET_SHOP_CERTIFICATES = 'getShopCertificates';
export const CREATE_SHOP_CERTIFICATE = 'createShopCertificate';
export const UPDATE_SHOP_CERTIFICATE = 'updateShopCertificate';
export const DELETE_SHOP_CERTIFICATE = 'deleteShopCertificate';

export const GET_PLATFORM_INVOICES = 'getPlatformInvoices';
