import type { AxiosPromise } from 'axios';
import client from '../client';
import payments from './payments';

const buildUrl = (path?: string | number) => (path ? `/merchants/${path}` : '/merchants');

export default {
  index: () => client.get(buildUrl()),
  show: (id: number, loadStripeAccount?: boolean): AxiosPromise => {
    return client.get(`${buildUrl(id)}?loadStripeAccount=${loadStripeAccount ? '1' : '0'}`);
  },
  update: (
    id: number,
    updates: { invoiceEmail?: string; notificationEmail?: string; phone?: string },
  ): AxiosPromise => {
    return client.patch(buildUrl(id), updates);
  },
  payments,
  platformOnboard: (id: number): AxiosPromise => {
    return client.post(`${buildUrl(id)}/platform-onboard`);
  },
};
