import type { Module } from 'vuex';
import { payouts } from '@contimo/api/src/api';
import { TBalanceResponse, TPayout, TPayouts } from '@contimo/api/src/api/payouts';
import { toDecimalPrice } from '@contimo/api/src/utils/integerPriceParser';
import type { TRootStore } from '@/store';
import { updateOrPushInArray } from '@/utils/store';
import { CREATE_PAYOUT, GET_PENDING_PAYOUTS, GET_BALANCE } from '../actionTypes';
import { SET_PENDING_PAYOUTS, SET_BALANCE } from '../mutationsTypes';
import {
  BALANCE_AVAILABLE_AMOUNT,
  BALANCE_PENDING_AMOUNT,
  BALANCE_TOTAL_AMOUNT,
  PAYOUT_PENDING_AMOUNT,
} from '../gettersTypes';

export interface IPagesStoreState {
  loading: boolean;
  submitLoading: boolean;
  payouts: {
    pending: TPayout[];
    total: number;
  };
  balance: TBalanceResponse | null;
}

type TPagesStore = Module<IPagesStoreState, TRootStore>;

const pagesStore: TPagesStore = {
  state: () => ({
    loading: false,
    submitLoading: false,
    payouts: {
      pending: [],
      total: 0,
    },
    balance: null,
  }),

  getters: {
    [BALANCE_AVAILABLE_AMOUNT]: (state) => toDecimalPrice(state.balance?.available.amount || 0),
    [BALANCE_PENDING_AMOUNT]: (state) => toDecimalPrice(state.balance?.pending.amount || 0),
    [BALANCE_TOTAL_AMOUNT]: (state) => toDecimalPrice(state.balance?.total || 0),
    [PAYOUT_PENDING_AMOUNT]: (state) => toDecimalPrice(state.payouts.total),
  },

  mutations: {
    [SET_PENDING_PAYOUTS](state, payouts: TPayouts) {
      state.payouts.total = payouts.total;
      payouts.pending.forEach((payout) => {
        updateOrPushInArray(state.payouts.pending, payout);
      });
    },
    [SET_BALANCE](state, balance: TBalanceResponse) {
      state.balance = balance;
    },
    setPayoutLoading(state, loading: boolean) {
      state.loading = loading;
    },
    setPayoutSubmitLoading(state, loading: boolean) {
      state.submitLoading = loading;
    },
  },

  actions: {
    async [GET_BALANCE]({ commit }, loading = true) {
      commit('setPayoutLoading', loading);
      try {
        const { data } = await payouts.getBalance();
        commit(SET_BALANCE, data);
        return data;
      } finally {
        commit('setPayoutLoading', false);
      }
    },
    async [GET_PENDING_PAYOUTS]({ commit }) {
      const { data } = await payouts.index();
      commit(SET_PENDING_PAYOUTS, data);
      return data;
    },
    async [CREATE_PAYOUT]({ commit, dispatch, state }): Promise<TPayout | void> {
      commit('setPayoutSubmitLoading', true);
      try {
        await dispatch(GET_BALANCE, false);
        if (!state.balance || state.balance.available.amount === 0) {
          return commit('setPayoutSubmitLoading', false);
        }
        const { data } = await payouts.store({ amount: state.balance.available.amount });
        await dispatch(GET_BALANCE, false);
        await dispatch(GET_PENDING_PAYOUTS);
        return data;
      } finally {
        commit('setPayoutSubmitLoading', false);
      }
    },
  },
};

export default pagesStore;
