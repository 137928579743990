import auth from './auth';
import user from './user';
import signup from './signup';
import ui from './ui';
import merchant from './merchant';
import postalCodes from './postalCodes';
import deliveryAreas from './deliveryAreas';
import merchantWastes from './merchantWastes';
import products from './products';
import shop from './shop';
import pages from './pages';
import managerInvites from './managerInvites';
import users from './users';
import managers from './managers';
import orders from './orders';
import payouts from './payouts';
import shopCertificates from './shopCertificates';
import invoices from './invoices';

export default {
  auth,
  user,
  signup,
  ui,
  merchant,
  postalCodes,
  deliveryAreas,
  merchantWastes,
  products,
  shop,
  pages,
  managerInvites,
  users,
  managers,
  orders,
  payouts,
  shopCertificates,
  invoices,
};
