import { AxiosPromise } from 'axios';
import buildPaginationQuery from '../utils/buildPaginationQuery';
import type { TApiPaginationQuery, TApiPaginationResult } from '@contimo/types/src/Api';
import client from './client';
import { IProduct } from '@contimo/types/src/ApiModels';
import { TMerchantWaste } from './merchantWastes';
import { ICreateAddressBody } from './address';

export interface IContainerOrder {
  id: number;
  deliveryDate: string;
  pickupDate: string;
  productId: number;
  merchantWasteId: number;
  deliveryDistance: number;
  deliveryAdditionalPrice: number;
  deliveryPrice: number;
  wastePrice: number;
  basePrice: number;
  durationPrice: number;
  applicationFee: number;
  totalPrice: number;
  createdAt: string;
  updatedAt: string;
  merchantWaste: TMerchantWaste;
  product: IProduct;
}
export interface IOrder {
  id: number;
  merchantId: number;
  customerId: number;
  containerOrderId: number;
  deliveryAddressId: number;
  invoiceAddressId: number | null | undefined;
  sellingPriceId: number;
  paymentProviderId: string;
  paymentPageUrl: string | null;
  status: 'init' | 'pending' | 'paid' | 'failed';
  customerNote: string | null;
  createdAt: string;
  updatedAt: string;
  containerOrder?: IContainerOrder;
}

export interface IOrderCreateBodyBase {
  productId: number;
  merchantWasteId: number;
  deliveryDate: Date;
  pickupDate: Date;
  customerNote: string | null;
}

export interface IOrderCreateBody extends IOrderCreateBodyBase {
  deliveryAddressId: number;
  invoiceAddressId?: number | null;
}

export interface IOrderCreateBodyAnonymous extends IOrderCreateBodyBase {
  deliveryAddress: Omit<ICreateAddressBody, 'type'>;
  invoiceAddressId?: Omit<ICreateAddressBody, 'type'>;
}

const buildUrl = (path?: string | number) => {
  return path ? `/orders/${path}` : '/orders';
};

export default {
  index: (pagination: TApiPaginationQuery = {}): AxiosPromise<TApiPaginationResult<IOrder[]>> => {
    return client.get(`${buildUrl()}${buildPaginationQuery(pagination)}`);
  },
  show: (id: number): AxiosPromise<IOrder> => client.get(buildUrl(id)),
  store: (body: IOrderCreateBody | IOrderCreateBodyAnonymous): AxiosPromise<IOrder> => {
    return client.post(buildUrl(), body);
  },
  cancelMyOrders: (): AxiosPromise<IOrder[]> => {
    return client.post('cancel-my-orders');
  },
};
