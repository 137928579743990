import axios from 'axios';
// import { TOKEN_STORE } from '../constants/api';

const url = process.env.VUE_APP_API_URL || '/';
// const getToken = () => localStorage.getItem(TOKEN_STORE);
const client = axios.create({
  baseURL: url,
  headers: {
    Accept: 'application/json',
  },
});

const storefrontClient = axios.create({
  baseURL: '/api',
  headers: {
    Accept: 'application/json',
  },
});

export const updateClientAuthToken = (token: string | null) => {
  if (token) {
    client.defaults.headers.Authorization = `Bearer ${token}`;
    storefrontClient.defaults.headers.Authorization = `Bearer ${token}`;
  } else {
    delete client.defaults.headers.Authorization;
    delete storefrontClient.defaults.headers.Authorization;
  }
};

export default client;

export { storefrontClient };
