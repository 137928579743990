import { AxiosPromise } from 'axios';
import Stripe from 'stripe';
import client from './client';

export type TPayout = Stripe.Payout;
export type TPayouts = {
  pending: TPayout[];
  total: number;
};

export type TBalance = {
  amount: number;
  currency: 'eur';
  source_types: {
    card: number;
  };
};

export type TBalanceResponse = {
  pending: TBalance;
  available: TBalance;
  total: number;
};

export type TPayoutCreateBody = {
  amount: number;
};

const buildUrl = (path?: string) => (path ? `/payouts/${path}` : '/payouts');

export default {
  index: (): AxiosPromise<TPayouts> => {
    return client.get(buildUrl());
  },
  store: (payload: TPayoutCreateBody): AxiosPromise<TPayout> => {
    return client.post(buildUrl(), payload);
  },
  getBalance: (): AxiosPromise<TBalanceResponse> => {
    return client.get(buildUrl('balance'));
  },
};
